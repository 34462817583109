// Models
import { IAnamneseAnswerState } from 'storage/assessmentAnamneseAnswer/models'
import { IAnamneseTemplateState } from 'storage/assessmentAnamneseTemplate/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useLayoutEffect } from 'react'

// Libs
import { ThemeContext } from 'styled-components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { triggerGetAnamneseAnswer } from 'storage/assessmentAnamneseAnswer/duck'
import { triggerGetAnamneseTemplate } from 'storage/assessmentAnamneseTemplate/duck'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import * as Styled from './styled'
import { Aligner, Button } from 'heeds-ds'
import { AnamnesePreview } from 'components'
import { triggerToast } from 'storage/general/duck'
import { getTermsOfResponsibilityPDF } from 'services/profile'

const AthleteAssessmentAnamneseAnswer: FC = () => {
  const { anamnese_id } = useParams()
  const { anamneseAnswer, loading: anamneseAnswerLoading } = useSelector<
    IStore,
    IAnamneseAnswerState
  >(({ assessmentAnamneseAnswer }) => assessmentAnamneseAnswer)
  const { anamneseTemplate, loading: anamneseTemplateloading } = useSelector<
    IStore,
    IAnamneseTemplateState
  >(({ assessmentAnamneseTemplate }) => assessmentAnamneseTemplate)
  const { setPagename } = useMenu()
  const location = useLocation()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktopSmall}px)`,
  )
  const navigate = useNavigate()
  const isDisplay =
    location.pathname.split('/').filter(Boolean).pop() === 'display'

  const handleGoBack = () => navigate(-1)

  useLayoutEffect(() => {
    if (anamnese_id) {
      isDisplay
        ? dispatch(triggerGetAnamneseTemplate({ id: anamnese_id }))
        : dispatch(triggerGetAnamneseAnswer(anamnese_id))
    }
  }, [dispatch, anamnese_id, isDisplay])

  useLayoutEffect(() => {
    setPagename('ANAMNESE')

    return () => {
      setPagename('ANAMNESES')
    }
  }, [setPagename])

  useEffect(() => {
    if (
      anamneseAnswerLoading === false &&
      !anamneseAnswer?.answer_date &&
      !isDisplay
    ) {
      const isSystemAssessment = anamneseAnswer?.personal === 0
      dispatch(
        triggerToast(
          isSystemAssessment
            ? {
                type: 'info',
                customTitle:
                  'Formulário enviado automaticamente para o seu aluno.',
                message:
                  'Não se preocupe, após o cadastro seu aluno já terá acesso a esse formulário.',
              }
            : {
                type: 'warning',
                customTitle: 'Questionário ainda não respondido.',
                message:
                  'Você receberá um aviso quando esse questionário for respondido',
              },
        ),
      )
    }
  }, [anamneseAnswer, dispatch, anamneseAnswerLoading, isDisplay])

  const downloadTermsPDF = async () => {
    try {
      const blob = await getTermsOfResponsibilityPDF()
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
      URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading Terms PDF:', error)
      dispatch(
        triggerToast({
          type: 'error',
          message: 'Error downloading Terms PDF. Please try again.',
        }),
      )
    }
  }

  return (
    <Styled.Container>
      {isDesktop && (
        <Aligner justify="space-between">
          <Styled.GoBackButton
            onClick={handleGoBack}
            track={buttonClickTracking}
            trackName="navigate_back_to_assessments"
          >
            <Styled.GoBackIcon />
            Voltar para anamneses
          </Styled.GoBackButton>
          {/* TODO: modificar quando for decidido como serao esses formularios do sistema */}
          {anamneseAnswer?.title?.toLowerCase().includes('par-q') && (
            <Button onClick={downloadTermsPDF} size="small">
              Baixar termo de responsabilidade
            </Button>
          )}
        </Aligner>
      )}

      <AnamnesePreview
        anamnese={isDisplay ? anamneseTemplate : anamneseAnswer}
        loading={anamneseAnswerLoading || anamneseTemplateloading}
      />
    </Styled.Container>
  )
}

export default AthleteAssessmentAnamneseAnswer
