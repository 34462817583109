// Models
import { IAthleteInfo } from 'services/athleteInfo/@types'
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IAthleteOutletContext } from 'layouts/AthleteRegisterLayout'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { athleteInformationSchema } from 'schemas'
import { cn } from 'utils/helpers/classess'
import { getCepByViaCep } from 'services/external'
import {
  prepareAthleteGeneralToDisplay,
  prepareAthleteGeneralToPayload,
} from 'filters/athlete'
import {
  triggerCreateAthlete,
  triggerLoadAthlete,
  triggerUpdateAthlete,
} from 'storage/athleteInfo/duck'
import { urls } from 'routes/paths'
import { useModal } from 'hooks'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Button,
  DatePicker,
  FormButton,
  Icon,
  Loading,
  RadioGroup,
} from 'heeds-ds'
import { ModalConfirmation, NewContentBox } from 'components'
import { TypeGenders } from 'models'

// Constants
import { GENDER_OPTIONS } from 'utils/constants'

const DESCRIPTION_ADDITIONAL_INFORMATION =
  'Contato de emergência será alguém que pode ajudar o seu aluno em algum momento de crise durante uma aula.'
const DESCRIPTION_GENERAL_DATA =
  'Preencha os dados pessoais do seu aluno. Nome, e-mail e sexo são obrigatórios.'

export interface FormInputs {
  address: string
  birthdate: string
  cep: string
  city: string
  comment: string
  complement: string
  email: string
  emergency_contact_name: string
  emergency_contact_phone_number: string
  instagram: string
  gender: TypeGenders
  name: string
  phone_number: string
  state: string
}

const AthleteGeneral: FC = () => {
  const { info, loading } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const { athleteOverview } = useSelector<IStore, IStore>((state) => state)
  const { overview } = athleteOverview
  const { id = '', isEditing } = useOutletContext<IAthleteOutletContext>()
  const { openModal, isVisible, closeModal } = useModal()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [cepIsLoading, setCepIsLoading] = useState(false)

  const formMethods = useForm<FormInputs>({
    resolver: yupResolver(athleteInformationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    delayError: 800,
  })
  const {
    formState: { isValid },
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
  } = formMethods

  const watchingCep = watch('cep')

  const onSubmit = (data: FormInputs) => {
    const payload: IAthleteInfo = {
      ...prepareAthleteGeneralToPayload(data),
      id: id ? Number(id) : 0,
    }
    if (isEditing) {
      dispatch(
        triggerUpdateAthlete({
          ...payload,
        }),
      )
    } else {
      dispatch(
        triggerCreateAthlete({
          ...payload,
          successCallback: () => openModal('success-create-user'),
        }),
      )
    }
  }

  const updateLinkedFields = useCallback(
    (address: string, city: string, state: string) => {
      setValue('address', address)
      setValue('city', city)
      setValue('state', state)
    },
    [setValue],
  )

  const navigateToAthletes = () => {
    closeModal()
    window.postMessage('NAVIGATE_TO_ATHLETES')
    navigate(urls.athletes)
  }

  const navigateToRoutines = () => {
    info && navigate(generatePath(urls.workoutRoutineCreate, { id: info.id }))
  }

  const getCep = useCallback(async () => {
    if (watchingCep) {
      setCepIsLoading(true)

      try {
        const { localidade, logradouro, uf } = await getCepByViaCep(
          watchingCep.replace('-', ''),
        )
        updateLinkedFields(logradouro ?? '', localidade ?? '', uf ?? '')
      } catch (error) {
        updateLinkedFields('', '', '')
        setError('cep', { message: 'CEP não encontrado!' })
      } finally {
        setCepIsLoading(false)
      }
    }
  }, [setError, updateLinkedFields, watchingCep])

  useEffect(() => {
    if (watchingCep?.match(/\d{5}-\d{3}/)) getCep()
  }, [getCep, watchingCep])

  useLayoutEffect(() => {
    if (isEditing && id) {
      dispatch(triggerLoadAthlete({ id: Number(id) }))
    }
  }, [dispatch, id, isEditing])

  useEffect(() => {
    if (info && info.id === Number(id)) {
      const formattedInfo = prepareAthleteGeneralToDisplay(info)

      reset(formattedInfo)
    }
  }, [id, info, reset])

  if (loading) {
    return <Loading active />
  }

  return (
    <FormProvider {...formMethods}>
      <form
        className="flex flex-1 flex-col overflow-auto xl:pl-10"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-6 px-6 pt-6 xl:grid xl:grid-cols-[30%_70%]">
          <div>
            <p className="text-copy3 font-bold">Dados Gerais</p>
            <p className="mt-2 text-copy3 text-text-subdued">
              {DESCRIPTION_GENERAL_DATA}
            </p>
          </div>
          <Styled.InputsGrid>
            <Styled.Input
              label="Nome Completo"
              mandatory
              placeholder="Digite o nome completo do aluno"
              name="name"
            />

            <Styled.Input
              label="E-mail"
              mandatory
              placeholder="Digite seu e-mail"
              name="email"
              type="email"
              disabled={isEditing && overview?.status === 'active'}
            />

            <Styled.Input
              label="Telefone"
              mask="phone"
              name="phone_number"
              placeholder="(00) 9 0000-0000"
            />

            <DatePicker
              displayError
              label="Data de Nascimento"
              mandatory
              name="birthdate"
              placeholder="Selecione a data de nascimento"
              scale="small"
              className="mb-[2px]"
            />

            <RadioGroup
              label="Sexo"
              mandatory
              name="gender"
              options={GENDER_OPTIONS}
              className="mb-5"
            />
          </Styled.InputsGrid>
        </div>
        <NewContentBox
          title={
            <Aligner gap="0.8rem">
              <Icon color={theme.colors.icon.critical} iconName="emergency" />
              <Body color={theme.colors.text.default} type="copy2" weight={700}>
                Contato de Emergência
              </Body>
            </Aligner>
          }
          description={isDesktop && DESCRIPTION_ADDITIONAL_INFORMATION}
          collapsible={!isDesktop}
          padding={!isDesktop ? '16px 24px 0' : ''}
        >
          {!isDesktop && (
            <Body
              color={theme.colors.text.disabled}
              margin="0 0 40px"
              type="copy3"
              weight={400}
            >
              {DESCRIPTION_ADDITIONAL_INFORMATION}
            </Body>
          )}
          <Styled.InputsGrid>
            <Styled.Input
              label="Nome do contato"
              placeholder="Digite o nome completo"
              name="emergency_contact_name"
            />

            <Styled.Input
              label="Telefone"
              mask="phone"
              name="emergency_contact_phone_number"
              placeholder="(00) 9 0000-0000"
            />
          </Styled.InputsGrid>
        </NewContentBox>
        <NewContentBox
          title={
            <Body color={theme.colors.text.default} type="copy2" weight={700}>
              Informações adicionais
            </Body>
          }
          description="Dados opcionais"
          collapsible={!isDesktop}
          margin={!isDesktop ? '0 0 24px' : ''}
          padding={!isDesktop ? '40px 24px 0' : ''}
        >
          <Styled.InputsGrid>
            <Styled.Input
              label="CEP"
              mask="cep"
              placeholder="Digite primeiro CEP do aluno"
              name="cep"
            />

            <Styled.Input
              disabled={cepIsLoading}
              label="Cidade"
              placeholder="Digite a cidade do aluno"
              name="city"
            />

            <Styled.Input
              disabled={cepIsLoading}
              label="Estado"
              placeholder="Digite o nome do estado"
              name="state"
            />

            <Styled.Input
              disabled={cepIsLoading}
              label="Endereço"
              placeholder="Digite o endereço do aluno"
              name="address"
            />

            <Styled.Input
              label="Complemento"
              placeholder="Digite o complemento do aluno"
              name="complement"
            />

            <Styled.Input
              label="Instagram"
              placeholder="Digite o instagram do aluno"
              name="instagram"
            />
          </Styled.InputsGrid>
        </NewContentBox>
      </form>
      <div
        className={cn(
          'flex justify-center border-t border-border-input bg-surface px-10 py-4 xl:justify-end',
          {
            'justify-between xl:justify-between': !isEditing,
          },
        )}
      >
        {!isEditing && (
          <Button
            cancel
            size={isDesktop ? 'xsmall' : 'small'}
            onClick={() => {
              window.postMessage('CLOSE_WEBVIEW')
              navigate(-1)
            }}
          >
            Cancelar
          </Button>
        )}
        <FormButton
          size={isDesktop ? 'xsmall' : 'small'}
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
          loading={loading}
        >
          Salvar
        </FormButton>
      </div>

      {isVisible === 'success-create-user' && (
        <ModalConfirmation
          cancelTitle="Lista de alunos"
          confirmTitle="Ir para criação de treino"
          description="Você agora pode criar treinos para esse aluno. Deseja ir para criação de treinos?"
          infoCancel
          onCancel={navigateToAthletes}
          onConfirm={navigateToRoutines}
          title="Aluno cadastrado!"
          width="fit-content"
        />
      )}
    </FormProvider>
  )
}

export default AthleteGeneral
