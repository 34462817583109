import { useEffect, useState } from 'react'

const useKeyboardVisible = (): boolean => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsKeyboardVisible(window.innerHeight < window.outerHeight - 100)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isKeyboardVisible
}

export default useKeyboardVisible
