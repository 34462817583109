// Models
import {
  IAnamneseAnswer,
  IAnamneseAnswerState,
} from 'storage/assessmentAnamneseAnswer/models'
import { IAnamneseTemplateState } from 'storage/assessmentAnamneseTemplate/models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect, useState, useCallback } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import {
  selectAnamneseAnswer,
  triggerCreateAnamneseAnswers,
  triggerLoadAnamneseAnswers,
  triggerLoadMoreAnamneseAnswers,
} from 'storage/assessmentAnamneseAnswer/duck'
import { triggerToastSuccess } from 'storage/general/duck'
import { triggerLoadAnamneseTemplates } from 'storage/assessmentAnamneseTemplate/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu, useModal } from 'hooks'

// Components
import { Button, CommonList } from 'heeds-ds'
import {
  AnamneseAnswerItem,
  Modal,
  ModalMoreOptions,
  ModalNewAnamneseTemplate,
} from 'components'
import Loader from 'components/lists/AnamneseAnswerItem/loader'

// Constants
export const ADD_NEW_ANAMNESE_ANSWERS_SUCCESS =
  'Seu aluno será notificado que existe uma nova anamnese para responder'

const dayInMs = 86400000

const page_size = 20

const columnNames = [
  { label: 'Nome da anamnese' },
  { label: 'Criado por' },
  { label: 'Data de envio' },
  { label: 'Data de resposta' },
  { label: 'Status' },
]

const headerGridTemplate =
  'grid-cols-[minmax(200px,1fr)repeat(4,minmax(20px,0.5fr))minmax(20px,0.35fr)]'

const AthleteAnamneses: FC = () => {
  const { id = '' } = useParams()
  const { anamneseAnswers, loading, next } = useSelector<
    IStore,
    IAnamneseAnswerState
  >(({ assessmentAnamneseAnswer }) => assessmentAnamneseAnswer)
  const { anamneseTemplates, loading: anamneseTemplatesLoading } = useSelector<
    IStore,
    IAnamneseTemplateState
  >(({ assessmentAnamneseTemplate }) => assessmentAnamneseTemplate)
  const { breakpoints } = useContext(ThemeContext)
  const { closeModal, openModal, isVisible } = useModal()
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)
  const navigate = useNavigate()

  const [selected, setSelected] = useState<IAnamneseAnswer>()

  const getOptions = (
    anamneseAnswer: IAnamneseAnswer,
  ): TMenuDropdownOption[] => {
    return [
      {
        icon: 'eye',
        label: 'Visualizar respostas',
        onClick: () => {
          navigateToAnamnese(anamneseAnswer)
          closeModal()
        },
      },
      {
        icon: 'send',
        label: 'Reenviar formulário',
        disabled: !anamneseAnswer.answer_date,
        onClick: () => {
          handleResendForm(anamneseAnswer)
        },
      },
    ]
  }

  const navigateToAnamnese = (anamnese: IAnamneseAnswer) => {
    dispatch(selectAnamneseAnswer(anamnese))
    navigate(
      generatePath(urls.athleteAssessmentAnamneseAnswer, {
        id,
        anamnese_id: anamnese.id,
      }),
    )
  }

  const copyAnamneseAnswer = (anamnese: IAnamneseAnswer) =>
    dispatch(
      triggerCreateAnamneseAnswers({
        anamnesis_template_ids: [anamnese.template_id],
        athlete_id: anamnese.athlete,
        successCallback: () =>
          dispatch(
            triggerToastSuccess({
              message: 'Formulário reenviado.',
            }),
          ),
      }),
    )

  const handleResendForm = (anamnese: IAnamneseAnswer) => {
    if (!anamnese.answer_date) return

    const today = new Date()
    const answeredDate = new Date(anamnese.answer_date)
    const diffInMs = Math.abs(today.getTime() - answeredDate.getTime())
    const diffInDays = Math.ceil(diffInMs / dayInMs)

    if (diffInDays > 180) return copyAnamneseAnswer(anamnese)

    openModal('modal-not-resend')
  }

  const handleAddAnamnese = (templateIds: string[]) => {
    const successCallback = () => {
      dispatch(
        triggerToastSuccess({
          message: ADD_NEW_ANAMNESE_ANSWERS_SUCCESS,
        }),
      ),
        handleReloadAnamnese()
    }
    id &&
      dispatch(
        triggerCreateAnamneseAnswers({
          athlete_id: Number(id),
          anamnesis_template_ids: templateIds,
          successCallback,
        }),
      )
  }

  const handleReloadAnamnese = useCallback(() => {
    const query = {
      athlete_pk: Number(id),
      page_size,
    }
    dispatch(triggerLoadAnamneseAnswers(query))
  }, [dispatch, id])

  const handleEndReached = () => {
    if (anamneseAnswers && !loading && next) {
      const query = {
        athlete_pk: Number(id),
        page: next,
        page_size,
      }
      dispatch(triggerLoadMoreAnamneseAnswers(query))
    }
  }

  const renderItems = (item: IAnamneseAnswer) => {
    return (
      <AnamneseAnswerItem
        anamneseAnswer={item}
        handleOptions={getOptions}
        key={item.id}
        onClick={() => navigateToAnamnese(item)}
        onSwipeClick={() => {
          setSelected(item)
          openModal('modal-more-options')
        }}
      />
    )
  }

  useLayoutEffect(() => {
    const query = {
      athlete_pk: Number(id),
      page_size,
    }
    dispatch(triggerLoadAnamneseAnswers(query))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useLayoutEffect(() => {
    setPagename('ANAMNESES')

    return () => {
      setPagename('DASHBOARD')
    }
  }, [dispatch, setPagename])

  useLayoutEffect(() => {
    if (!anamneseTemplates && !anamneseTemplatesLoading) {
      const query = {
        page_size,
      }
      dispatch(triggerLoadAnamneseTemplates(query))
    }
  }, [anamneseTemplates, dispatch, anamneseTemplatesLoading])

  return (
    <div className="relative flex h-full flex-col items-center overflow-auto md:items-stretch">
      <CommonList<IAnamneseAnswer>
        columns={columnNames}
        columnsFormat={headerGridTemplate}
        data={anamneseAnswers || []}
        hasMore={!!next}
        loaderComponent={<Loader />}
        onEndReached={handleEndReached}
        padding="py-4 pr-4 pl-6"
        renderItem={renderItems}
        showHeader={isTablet}
        refreshing={loading}
        listEmptyComponent={
          <div className="flex h-4/5 items-center justify-center">
            <p className="text-center text-copy4 text-text-subdued">
              Você ainda não enviou nenhuma Anamneses para esse aluno.
            </p>
          </div>
        }
      />
      <div className="sticky bottom-0 flex w-full justify-center border border-t-border-input bg-surface px-10 py-4 md:justify-end md:gap-4">
        <Button
          size="xsmall"
          track={buttonClickTracking}
          variation="borderless"
          trackName="open_modal_create-athlete-new-anamnese"
          onClick={() => openModal('create-athlete-new-anamnese-modal')}
        >
          Criar um novo modelo
        </Button>
        <Button
          size="xsmall"
          track={buttonClickTracking}
          trackName="open_modal_new_anamnese"
          onClick={() =>
            openModal(
              anamneseTemplates && anamneseTemplates?.length > 0
                ? 'new-anamnese-modal'
                : 'create-new-anamnese-modal',
            )
          }
        >
          + Adicionar anamnese
        </Button>
      </div>

      {!isTablet && isVisible === 'modal-more-options' && (
        <ModalMoreOptions options={selected ? getOptions(selected) : []} />
      )}

      {isVisible === 'create-athlete-new-anamnese-modal' && (
        <Modal
          maxWidth="620px"
          title="Adicionar anamnese"
          description="Você será redirecionado para a biblioteca, onde poderá criar um novo modelo de anamnese. Esse modelo ficará salvo e disponível para uso com outros alunos. Deseja prosseguir para a criação?"
          icon="info"
          primaryButton={{
            name: 'Ir para criação de modelo',
            onClick: () => {
              navigate(
                generatePath(urls.libraryAthleteAnamneseCreate, {
                  athlete_id: id,
                }),
              ),
                closeModal()
            },
          }}
          secondaryButton={{ name: 'Cancelar' }}
        />
      )}

      {isVisible === 'create-new-anamnese-modal' && (
        <Modal
          maxWidth="620px"
          title="Adicionar anamnese"
          description="Você ainda não criou uma anamnese na sua biblioteca. Deseja ir para criação de um modelo?"
          icon="info"
          primaryButton={{
            name: 'Ir para criação de modelo',
            onClick: () => {
              navigate(generatePath(urls.libraryAnamneseCreate)), closeModal()
            },
          }}
          secondaryButton={{ name: 'Cancelar' }}
        />
      )}

      {isVisible === 'new-anamnese-modal' && (
        <ModalNewAnamneseTemplate onSend={handleAddAnamnese} />
      )}

      {isVisible === 'modal-not-resend' && (
        <Modal
          maxWidth="620px"
          title="Reenviar formulário"
          description="Você poderá reenviar o formulário só após 6 meses da data de resposta do seu aluno."
          icon="info"
          primaryButton={{ name: 'Entendi', onClick: () => closeModal() }}
        />
      )}
    </div>
  )
}

export default AthleteAnamneses
