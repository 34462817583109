// Models
import { IAppointmentTime } from 'storage/profile/models'
import { IFormFields } from 'views/dashboard/athlete/AthleteSchedule'
import { IModelInput, TMuscleChoice, TWeekdays, TypeUser } from 'models'
import { IWorkoutModelData } from 'storage/workoutModel/models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import { IconsType as StylizedIconsType } from 'heeds-ds/src/components/UI/Icons/StylizedIcons/values'

// Libraries
import moment from 'moment'

// Constants
import { MUSCLE_CHOICES } from 'utils/constants'

export enum EDaysName {
  sun = 'Domingo',
  mon = 'Segunda',
  tue = 'Terça',
  wed = 'Quarta',
  thu = 'Quinta',
  fri = 'Sexta',
  sat = 'Sábado',
}

export const daysName = {
  sun: 'Domingo',
  mon: 'Segunda',
  tue: 'Terça',
  wed: 'Quarta',
  thu: 'Quinta',
  fri: 'Sexta',
  sat: 'Sábado',
}

export const isWebviewAccess = () => {
  const webview = localStorage.getItem('webview')
  return !!webview
}

export const getWebviewUserType = () => {
  if (!isWebviewAccess()) return
  const userType = localStorage.getItem('webview-user')
  return userType ? (userType as TypeUser) : undefined
}

export const changePositionInArray = <T>(
  arr: Array<T>,
  from: number,
  to: number,
) => {
  arr.splice(to, 0, arr.splice(from, 1)[0])
  return arr
}

export const convertOptionToFilter = (
  options: Array<{ label: string; value: string }>,
  param: string,
) => {
  if (!options) return []

  return options.map((option) => ({ ...option, param }))
}

export const dateFormatEnglishToPortuguese = (date?: string) => {
  if (!date) return ''
  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
}

export const dateFormatPortugueseToEnglish = (date?: string) => {
  if (typeof date === 'string') {
    if (date?.includes('-')) {
      return date
    }
  }
  return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
}

export function firstLetter(name: string) {
  return name.trim().charAt(0).toUpperCase()
}

export function getDateFromPastYears(yearsAgo: number): Date {
  const pastTimeStamp = new Date().setFullYear(
    new Date().getFullYear() - yearsAgo,
  )
  return new Date(pastTimeStamp)
}

export function getAlphabeticLetter(position: number) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  return alphabet[position % 26]
}

export function getWeekDay(position: number) {
  const weekDays = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo',
  ]
  return weekDays[position % 7]
}

export const getMaskedValue = (measure?: string) => {
  if (!measure) return ''

  const measures = {
    S: 'seg',
    M: 'min',
    H: 'hs',
  }
  return measures[measure as keyof typeof measures]
}

export const getModelName = (index: number) =>
  `Treino ${getAlphabeticLetter(index)}`

export const getMuscleGroup = (muscleId: number) => {
  const isAValidMuscleId = Object.keys(MUSCLE_CHOICES).includes(
    String(muscleId),
  )

  if (!isAValidMuscleId) return ''

  const groupID = String(muscleId - (muscleId % 1000)) as TMuscleChoice

  return MUSCLE_CHOICES[groupID].split(' ').reverse()[0]
}

export const getWorkoutModelsExerciseLenght = (model?: IModelInput): number => {
  if (!model) return 0

  if (Object.values(model.workout_set).length === 0) return 0

  return Object.values(model.workout_set)
    .map((setToExercise) => setToExercise.exercises.length)
    .reduce((accumulator, currentValue) => accumulator + currentValue)
}

export const getWorkoutRoutineFilter = (
  routine: IWorkoutRoutine,
): 'current' | 'history' | 'scheduled' => {
  const { start_date, end_date } = routine
  const now = new Date()

  if (!start_date) {
    if (!end_date) return 'current'

    const end = new Date(end_date)

    if (end < now) return 'history'
    return 'current'
  }

  const start = new Date(start_date)

  if (!end_date) {
    if (start > now) return 'scheduled'
    return 'current'
  }

  const end = new Date(end_date)

  if (end < now) return 'history'
  if (start > now) return 'scheduled'
  return 'current'
}

export function getGoalIcon(goal?: string): StylizedIconsType {
  switch (goal) {
    case 'Hipertrofia':
      return 'hypertrophy'
    case 'Redução de Gordura':
      return 'fatLoss'
    case 'Definição Muscular':
      return 'definition'
    case 'Prevenção de Lesões':
      return 'injuryPrevention'
    case 'Condicionamento Físico':
      return 'conditioning'
    case 'Qualidade de Vida':
      return 'lifeQuality'
    case 'Treinamento Funcional':
      return 'functionalTraining'
    default:
      return 'genericWorkout'
  }
}

export function mergeCref(cref = '', cref_UF = '') {
  return `${cref}/${cref_UF}`
}

export function splitCref(cref: string) {
  const crefSplitted = cref.split('/')
  const crefNumbers = crefSplitted[0]
  const crefUF = crefSplitted[1]
  return [crefNumbers, crefUF]
}

export function renderInitials(name = '') {
  const { first_name, last_name } = splitFullname(name)

  return (
    first_name?.charAt(0).toUpperCase() + last_name?.charAt(0).toUpperCase()
  )
}

export function renderDaysInFrequency(days?: TWeekdays[]) {
  if (!days) return

  if (days.length === 7) {
    return 'Todos os dias'
  } else if (
    days.length === 5 &&
    !days.includes('sat') &&
    !days.includes('sun')
  ) {
    return 'Dias de semana'
  } else if (
    days.length === 2 &&
    days.includes('sat') &&
    days.includes('sun')
  ) {
    return 'Finais de semana'
  } else {
    const selectedDays = Object.keys(EDaysName).filter((day) =>
      days.includes(day as TWeekdays),
    )
    return selectedDays
      .map((selectedDay, index) => {
        const day = selectedDay as TWeekdays
        if (index === days.length - 1 && days.length > 1) {
          return ` e ${EDaysName[day]}s`
        }
        return index === 0 ? `${EDaysName[day]}s` : `, ${EDaysName[day]}s`
      })
      .join('')
  }
}

export function resizeTextArea(textAreaRef: HTMLTextAreaElement) {
  textAreaRef.style.height = '0px'
  textAreaRef.style.height = `${textAreaRef.scrollHeight}px`
}

export function sliceStringByLength(text: string, length: number) {
  if (text.length + 4 > length) {
    return text.slice(0, length) + '... '
  }
  return text.slice(0, length)
}

export function splitFullname(name = '') {
  const [first_name, ...restNames] = name.trim().split(' ')
  const last_name = restNames.length ? restNames.reverse()[0] : ''

  return { first_name, last_name }
}

export const toSnakeCase = (text?: string) =>
  text
    ?.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
    )
    ?.map((x) => x.toLowerCase())
    .join('_')

export function uid() {
  return Date.now().toString(36) + Math.random().toString(36).replace('0.', '')
}

export function genderHandler(gender: string) {
  switch (gender) {
    case 'M':
      return 'Masculino'
    case 'F':
      return 'Feminino'
  }
}

export function hourMinuteFormat(time: string) {
  const split = time?.slice(0, 5).split(':')
  const leftSide =
    split[0] === '00'
      ? '0'
      : split[0].slice(0, 1) === '0'
      ? split[0].slice(1, 2)
      : split[0]
  const rightSide = split[1] === '00' ? '' : split[1]
  return leftSide + 'h' + rightSide
}

export function generateScheduleResetObject(
  appointmentTimes: IAppointmentTime[],
) {
  const object: IFormFields = {} as IFormFields
  appointmentTimes?.forEach((appointment: IAppointmentTime) => {
    object[appointment.day] = {
      id: appointment.id,
      day: appointment.day,
      start: appointment?.start.slice(0, 5),
      end: appointment?.end.slice(0, 5),
      address: appointment?.address,
    }
  })
  return object
}

export function calculateAge(birthdate: string) {
  const date = new Date(birthdate)
  const currentDate = new Date()
  const difference = currentDate.getTime() - date.getTime()
  const ageDate = new Date(difference)
  const years = ageDate.getUTCFullYear() - 1970

  return years
}

export function getMuscleGroups(model: IWorkoutModelData) {
  const muscleSet = new Set()
  model.workout_set?.forEach((set) =>
    set.exercises.forEach((setToExercise) =>
      setToExercise?.exercise?.agonists_muscle?.forEach((muscleId) =>
        muscleSet.add(getMuscleGroup(muscleId)),
      ),
    ),
  )

  return Array.from(muscleSet).join(' / ')
}

export function handleTimeInterval(start: string, end: string) {
  const [hourStart, minuteStart] = start.split('T')[1].split(':').map(Number)
  const [hourEnd, minuteEnd] = end.split('T')[1].split(':').map(Number)

  const startDate = new Date()
  startDate.setHours(hourStart, minuteStart, 0)

  const endDate = new Date()
  endDate.setHours(hourEnd, minuteEnd, 0)

  const deferenceInMS = Math.abs(Number(startDate) - Number(endDate))

  const finalHour = Math.floor(deferenceInMS / (1000 * 60 * 60))
  const finalMinute = Math.floor(
    (deferenceInMS % (1000 * 60 * 60)) / (1000 * 60),
  )

  if (finalHour && finalMinute) {
    return `${finalHour}h${finalMinute}`
  }
  if (finalHour) {
    return `${finalHour}h`
  }
  if (finalMinute) {
    return `${finalMinute}min`
  }
}

export function isVideo(file: string) {
  if (!file) {
    return
  }
  const videoExtensions = ['mp4', 'mov', 'avi', 'webm']
  const extension = file?.split('.')?.pop()?.toLowerCase()
  return videoExtensions?.includes(extension ?? '')
}

export const base64ToBlob = (base64: string, mimeType = 'application/pdf') => {
  const byteCharacters = atob(base64)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  return new Blob([byteArray], { type: mimeType })
}
