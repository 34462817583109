/* eslint-disable @typescript-eslint/no-explicit-any */
// Models
import {
  IAppointmentTime,
  IPersonalAppointmentTimes,
  IPersonalAthletesSchedules,
  IPersonalData,
  IUserInfo,
} from 'storage/profile/models/index'
import * as M from './models'

// Misc
import api from 'lib/axios/api'
import { base64ToBlob } from 'utils/functions'

export const getPersonalProfile: M.TGetPersonalProfile = async () => {
  try {
    const response = await api.get(`personal/self/`)
    const data: IPersonalData = response.data

    return data
  } catch (error: any) {
    if (error?.response?.data?.detail) {
      throw new Error(error.response.data.detail)
    }
    throw new Error(error.message)
  }
}

export const updatePersonalProfile: M.TUpdatePersonal = async (formdata) => {
  try {
    const response = await api.patch(`personal/self/`, formdata)
    const data: IPersonalData = response.data

    return data
  } catch (error: any) {
    if (error?.response?.data?.detail) {
      throw new Error(error.response.data.detail)
    }
    throw new Error(error.message)
  }
}

export const getPersonalAppointmentTimes: M.TGetAppointmentTimes = async () => {
  try {
    const response = await api.get(`personal/appointment-time/`)
    const data: IPersonalAppointmentTimes = response.data
    return data
  } catch (error: any) {
    if (error?.response?.data?.detail) {
      throw new Error(error.response.data.detail)
    }
    throw new Error(error.message)
  }
}

export const createOrUpdatePersonalAppointmentTimes: M.TCreateOrUpdatePersonalAppointmentTimes =
  async (formdata) => {
    try {
      const response = await api.post(`personal/appointment-time/`, {
        appointment_times: formdata,
      })
      const data: IAppointmentTime[] = response.data.appointment_times
      return data
    } catch (error: any) {
      if (error?.response?.data) {
        throw new Error(error.response.data)
      }
      throw new Error(error.message)
    }
  }

export const getAllAthletesTimeSchedule: M.TGetAllAthletesTimeSchedule =
  async () => {
    try {
      const response = await api.get(`personal/self/schedules/`)
      const data: IPersonalAthletesSchedules = response.data
      return data
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        throw new Error(error.response.data.detail)
      }
      throw new Error(error.message)
    }
  }

export const getUserInfo: M.TGetInfo = async () => {
  try {
    const { data }: { data: IUserInfo } = await api.get(`users/self/`)
    return data
  } catch (error: any) {
    if (error?.response?.data?.detail) {
      throw new Error(error.response.data.detail)
    }
    throw new Error(error.message)
  }
}

export const getTermsOfResponsibilityPDF = async (): Promise<Blob> => {
  try {
    const { data } = await api.get(`personal/term-of-responsibility-pdf/`, {
      responseType: 'text',
      timeout: 20000,
    })

    return base64ToBlob(data)
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}
