// Models
import { IExerciseData, IExerciseState } from 'storage/exercise/models'
import {
  IModelInput,
  IWorkoutSetInput,
  TExercisesGroupFilter,
  TSetSpecialSet,
} from 'models'
import { IWorkoutModelData } from 'storage/workoutModel/models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import {
  getWebviewUserType,
  getWorkoutModelsExerciseLenght,
  uid,
} from 'utils/functions'
import { prepareModelsToDisplay } from 'filters/workouts'
import { useMenu, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import { Body, Button, CommonList, Icon } from 'heeds-ds'
import {
  ExerciseItem,
  LoaderExerciseItem,
  ModalExerciseImage,
  ModalMuscleGroups,
} from 'components'
import { ModalWorkoutModelTemplates } from 'components/modals'
import WorkoutModelHeader from 'components/WorkoutModelHeader'
import ExerciseSearchBar from 'blocks/dashboard/workout/ExerciseSearchBar'

type Props = {
  addWorkoutModel: (model: IModelInput) => void
  addWorkoutSet: (modelId: string, workoutSet: IWorkoutSetInput) => void
  changePage: (page: string) => void
  duplicateWorkoutModel: () => void
  filters: TExercisesGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  onChangeFilters: (filters: TExercisesGroupFilter[]) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  removeFilter: (filter: TExercisesGroupFilter) => void
  removeWorkoutModel: () => void
  renameWorkoutModel: () => void
  selectWorkoutModel: (index: number) => void
  selectedModelIndex: number
  setSpecialSet: TSetSpecialSet
  stateModels: IModelInput[]
  updateWorkoutModel: (modelId: string, updatedModel: IModelInput) => void
  visibleHeader?: boolean
}

const WorkoutModelsMobileHome: FC<Props> = ({
  addWorkoutModel,
  addWorkoutSet,
  changePage,
  duplicateWorkoutModel,
  filters,
  inputValue,
  loadMoreExercises,
  onChangeFilters,
  onSearchChange,
  removeFilter,
  removeWorkoutModel,
  renameWorkoutModel,
  selectWorkoutModel,
  selectedModelIndex,
  setSpecialSet,
  stateModels,
  updateWorkoutModel,
  visibleHeader = true,
}) => {
  const { exercises, loading } = useSelector<IStore, IExerciseState>(
    (state) => state.exercise,
  )
  const { setPagename } = useMenu()
  const { closeModal, isVisible, openModal } = useModal()
  const theme = useContext(ThemeContext)
  const userType = getWebviewUserType()

  const buttonRef = useRef<HTMLButtonElement>(null)

  const [selectedExercise, setSelectedExercise] = useState<
    IExerciseData | undefined
  >()

  const [showExercises, setShowExercises] = useState(false)

  const isATemplateView = location.pathname.split('/')[2] === 'biblioteca'

  const addNewWorkoutSet = useCallback(
    (exercise: IExerciseData) => {
      const modelId = stateModels[selectedModelIndex].id
      const setId = 'NEW' + uid()

      const newWorkoutSet: IWorkoutSetInput = {
        id: setId,
        type: 'normal',
        quantity: '',
        interval: {
          value: '',
          measure: 'S',
        },
        exercises: [{ id: uid(), exercise, repetitions: '' }],
      }
      addWorkoutSet(modelId, newWorkoutSet)
    },
    [addWorkoutSet, selectedModelIndex, stateModels],
  )

  const openExerciseGifModal = useCallback(
    (exercise: IExerciseData) => {
      setSelectedExercise(exercise)
      openModal('mobile-exercise-gif-modal')
    },
    [openModal],
  )

  const countSets = useMemo(
    () => getWorkoutModelsExerciseLenght(stateModels[selectedModelIndex]),
    [selectedModelIndex, stateModels],
  )

  const exercisesLengthText = useMemo(() => {
    return countSets === 1
      ? 'Ver 1 exercício adicionado'
      : `Ver ${+countSets} exercícios adicionados`
  }, [countSets])

  const renderExercise = useCallback(
    (exercise: IExerciseData) => {
      return (
        <ExerciseItem
          exercise={exercise}
          key={exercise.id}
          margin="0 0 16px"
          onClick={() => addNewWorkoutSet(exercise)}
          onClickImage={openExerciseGifModal}
        />
      )
    },
    [addNewWorkoutSet, openExerciseGifModal],
  )

  const handleLibraryModel = (templateModel: IWorkoutModelData) => {
    const preparedModel = prepareModelsToDisplay([templateModel])[0]

    if (stateModels && selectedModelIndex !== undefined) {
      const selectedModel = stateModels?.[selectedModelIndex]
      updateWorkoutModel?.(selectedModel.id, {
        ...preparedModel,
        id: selectedModel.id,
      })
    }
  }

  const closeExerciseGifModal = () => {
    setSelectedExercise(undefined)
    closeModal()
  }

  useLayoutEffect(() => {
    setSpecialSet(undefined)
    setPagename(stateModels[selectedModelIndex].name.toUpperCase())

    return () => setPagename('DASHBOARD')
  }, [selectedModelIndex, setPagename, setSpecialSet, stateModels])

  useEffect(() => {
    countSets === 0 ? setShowExercises(false) : setShowExercises(true)
  }, [countSets])

  return (
    <Styled.Container>
      {visibleHeader && (
        <WorkoutModelHeader
          addWorkoutModel={addWorkoutModel}
          duplicateWorkoutModel={duplicateWorkoutModel}
          models={stateModels}
          removeWorkoutModel={removeWorkoutModel}
          renameWorkoutModel={renameWorkoutModel}
          selectWorkoutModel={selectWorkoutModel}
          selectedModelIndex={selectedModelIndex}
        />
      )}

      {!isATemplateView && !showExercises && userType !== 'athlete' ? (
        <Styled.EmptyModelContainer>
          <Body type="copy3" align="center" margin="0 0 32px">
            Agora você pode adicionar um treino pronto da biblioteca ou escolher
            os exercícios individualmente. Utilize os botões abaixo para
            configurar seu treino.
          </Body>
          <Button
            onClick={() => openModal('add-new-model-modal')}
            ref={buttonRef}
            track={buttonClickTracking}
            trackName="open_add-new-model-moda"
          >
            <Icon iconName="add" color={theme.colors.icon.onPrimary} />
            Adicionar da biblioteca
          </Button>
          <Body type="copy3" margin="16px 0">
            ou
          </Body>
          <Button onClick={() => setShowExercises(true)}>
            Escolher exercícios
          </Button>
        </Styled.EmptyModelContainer>
      ) : (
        <>
          <Styled.FixedContainer>
            <ExerciseSearchBar
              filters={filters}
              onClickFilter={() => openModal('muscle-group-modal')}
              onSearchChange={onSearchChange}
              removeFilter={removeFilter}
              searchQuery={inputValue}
            />

            <Styled.ExercisesCard>
              <Styled.ExercisesText>{exercisesLengthText}</Styled.ExercisesText>

              <Styled.ArrowButton
                disabled={countSets === 0}
                onClick={() => changePage('2')}
              />
            </Styled.ExercisesCard>
          </Styled.FixedContainer>

          {loading ? (
            <div className="flex flex-col gap-6 overflow-auto">
              {Array(7)
                .fill(1)
                .map((_, index) => (
                  <LoaderExerciseItem key={index} />
                ))}
            </div>
          ) : (
            <CommonList<IExerciseData>
              columns={[]}
              data={exercises?.results || []}
              hasMore={!!exercises?.next}
              loaderComponent={<LoaderExerciseItem />}
              onEndReached={loadMoreExercises}
              renderItem={renderExercise}
              scrollableDiv="dashboard-content"
              showHeader={false}
            />
          )}
        </>
      )}

      {isVisible === 'mobile-exercise-gif-modal' && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeExerciseGifModal}
        />
      )}

      {isVisible === 'muscle-group-modal' && (
        <ModalMuscleGroups filters={filters} onChange={onChangeFilters} />
      )}

      {isVisible === 'add-new-model-modal' && (
        <ModalWorkoutModelTemplates
          onSelectWorkoutModel={(model) => handleLibraryModel(model)}
        />
      )}
    </Styled.Container>
  )
}

export default WorkoutModelsMobileHome
